<template>
  <div class="menu-container" id="header">
    <router-link to="/"><span class="item" :class="fullPath === '/'? 'active':''">首页</span></router-link>
    <router-link to="/#product"><span class="item" :class="fullPath === '/#product'?'active':''">产品中心</span></router-link>
    <router-link to="/#solution"><span class="item" :class="fullPath === '/#solution'?'active':''">解决方案</span></router-link>
    <router-link to="/about"><span class="item" :class="fullPath === '/about'?'active':''">关于我们</span></router-link>
  </div>
</template>
<script>
export default {
  computed: {
    path () {
      return this.$route.path || ''
    },
    hash () {
      return this.$route.hash || ''
    },
    fullPath () {
      return this.path + this.hash
    }
  },
  methods: {

  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
.menu-container {
  font-size: 18px;
  color: #fff;
  a {
      margin-left: 64px;
  }
  a:first-child {
    margin-left: 0;
  }
  .item {
    border-bottom: 2px solid transparent;
    padding-bottom: 7px;
  }
  .active {
    border-bottom-color: #fff;
  }
}
</style>
