import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout/index.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'root',
    component: Layout,
    children: [{
      path: '',
      name: 'home',
      component: () => import('../views/home/index.vue')
    }, {
      path: '/about',
      name: 'about',
      component: () => import('../views/about/index.vue')
    }]
  },
  {
    path: '/appointment',
    name: 'appointment',
    component: () => import('../views/appointment/index.vue'),
    meta: {
      hide: true
    }
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
