import Vue from 'vue'
import { Form, FormItem, Input,Table,TableColumn } from 'element-ui'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './routerListener'
import 'animate.css'
import 'element-ui/lib/theme-chalk/index.css'
import './styles/index.scss'

Vue.config.productionTip = false
const plugins = [Form, FormItem, Input,Table,TableColumn]
plugins.forEach(plugin => {
  Vue.use(plugin)
})

new Vue({
  router,
  store,
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  },
  render: h => h(App)
}).$mount('#app')
