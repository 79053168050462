<template>
  <div class="header-container">
    <div class="main-box">
      <div class="logo">
        <span @click="linkTo" style="cursor:pointer;">
            <img src="../assets/imgs/logo2x.png">
        </span>
      </div>
      <Menu></Menu>
    </div>
  </div>
</template>
<script>
import Menu from './Menu'
export default {
  components: {
    Menu
  },
  methods: {
    linkTo () {
      const path = this.$route.path || '/'
      const hash = this.$route.hash || ''
      if (path === '/' && hash === '') {
        document.querySelector('#home').scrollIntoView({
          behavior: 'smooth'
        })
      } else {
        this.$router.push({
          path: '/'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 72px;
  right: 0;
  display: flex;
  justify-content: center;
  background: #232323;
  color: #ffff;
  font-size: 16px;
  z-index: 100000000;
  .main-box {
    width: 1366px;
    padding: 0 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      img {
        height: 40px;;
      }
    }
    .menu {
      a {
        margin-left: 64px;
      }
    }
  }

}
</style>
