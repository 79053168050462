<template>
  <div class="layout-container">
    <Header></Header>
    <div class="main-container">
      <transition
        name="fade-transform"
        mode="out-in"
      >
        <router-view></router-view>
      </transition>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    Header,
    Footer
  }
}
</script>
<style lang="scss" scoped>
.layout-container {
  // widows: 100%;
  // height: 100%;
  .main-container {
    margin-top: 72px;
  }
}
</style>
