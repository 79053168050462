<template>
  <div class="footer-container">
    <div class="main-box">
      <div class="top">
        <Menu></Menu>
      </div>
      <hr>
      <div class="bottom">版权所有 · 北京好运宝有限公司 | <a href="http://beian.miit.gov.cn/" target="view_window">京ICP备2021006795号-1</a> © 2022 haoyunbao.com All Rights Reserved</div>
    </div>
  </div>
</template>
<script>
import Menu from './Menu'
export default {
  components: {
    Menu
  }
}
</script>

<style lang="scss" scoped>
.footer-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 24px 0 39px 0;
  background: #232323;
  .main-box {
    width: 1366px;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .phone {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .label {
          font-size: 10px;
          color: #919191;
        }
        .context {
          margin-top: 3px;
          font-size: 20px;
          color: #fff;
        }
      }
    }
    hr {
      background: #d4d4d4;
      height: 1px;
      border: none;
      opacity: 0.2;
    }
    .bottom {
      margin-top: 15px;
      font-size: 10px;
      text-align: left;
      color: #919191;
    }
  }
  /deep/ {
    .menu-container {
      .item {
        padding-bottom: 16px;
      }
    }
  }
}
</style>
